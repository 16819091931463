import axios, {AxiosResponse} from 'axios'
import jwtDecode from 'jwt-decode'
import qs from 'query-string'

import {requestRevokeRefreshToken} from '../interceptors'
import {convertAuthToApiToken, extractBaseUrlFromAccessToken} from '../tools'
import {
  AuthenticatorResponse,
  AuthRequestProvider,
  BackendSelector,
  ClientConfig,
  DecodedToken,
  LoginFlowState,
  LoginResponse,
  LoginStorage,
  OpenIdConfig,
  Product
} from '../types'

type TokenResponse = {
  id_token: string
  access_token: string
  expires_in: number
  token_type: string
  refresh_token: string
  scope: string
}

export type AuthRedirectQueryParams = {
  code: string
  scope: string
  state: string
  session_state: string
  error?: string
  error_description?: string
  logoutId?: string
}

// This class takes care of communicating with the Authenticator App (send/recv redirects)
export class BrowserLoginFlow {
  constructor(
    storage: LoginStorage,
    authRequestProvider: AuthRequestProvider,
    backendSelector: BackendSelector
  ) {
    this.storage = storage
    this.authRequestProvider = authRequestProvider
    this.authenticatorUrl = backendSelector.getSelectedBackend().AUTH_URL
    this.backendSelector = backendSelector
  }

  storage: LoginStorage
  authRequestProvider: AuthRequestProvider
  authenticatorUrl: string
  backendSelector: BackendSelector

  async startLogoutProcess() {
    const openIdConfig = await this.fetchOpenIdConfig()
    const endSessionUrl = openIdConfig.data?.end_session_endpoint || ''

    const clientConfig: ClientConfig = {
      loginStorage: this.storage,
      authRequestProvider: this.authRequestProvider,
      loginFlow: this,
      backendSelector: this.backendSelector
    }

    const storageTokenUrl = extractBaseUrlFromAccessToken(this.storage.getToken().accessToken)
    const issuerUrl = openIdConfig.data?.issuer

    const tokenUrl = openIdConfig.data?.token_endpoint || ''

    // if there was issuer change during user's session then fetch new token
    if (storageTokenUrl !== issuerUrl) {
      try {
        const params = new URLSearchParams()
        params.append('grant_type', 'refresh_token')
        params.append('client_id', 'HConnect')
        params.append('refresh_token', this.storage.getToken().refreshToken)

        const tokenResponse = await axios({
          method: 'post',
          data: params,
          url: tokenUrl,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })

        const tokenData: Required<AuthenticatorResponse> = tokenResponse.data
        const convertedToken = convertAuthToApiToken(tokenData)
        this.storage.setToken(convertedToken)
      } catch (error: any) {
        const {response, JSONResponseValidationErrors} = error
        if (JSONResponseValidationErrors) {
          console.error(`Invalid token: ${JSON.stringify(JSONResponseValidationErrors)}`)
        }

        const {message} = error

        const isRefreshTokenInvalid = !!response && response.status === 400
        if (!isRefreshTokenInvalid) {
          console.debug(`Failed to fetch new tokens: ${message}`)
          throw error
        } else {
          console.warn(`Refresh token invalid: ${message}`)
          this.storage.resetFlow()
          this.storage.resetToken()
          await clientConfig.loginFlow.startLoginProcess()
        }
      }
    }

    const signOutRequest = this.authRequestProvider.createSignOutRequest(
      endSessionUrl,
      this.storage
    )

    // before logout revoke refresh token
    await requestRevokeRefreshToken(clientConfig)

    // clear token on product side
    this.storage.resetToken()
    window.location.href = signOutRequest.url
  }

  /**
   * Redirects to the Authenticator application for letting the user log-in.
   */
  async startLoginProcess(clientId?: string) {
    const openIdConfig = await this.fetchOpenIdConfig()
    const authenticatorUrl = openIdConfig.data?.authorization_endpoint || ''
    const signInRequest = await this.authRequestProvider.createSignInRequest(
      authenticatorUrl,
      {},
      clientId
    )
    this.storage.setFlow(signInRequest.flow)
    window.location.href = signInRequest.url
  }

  /**
   * Redirects to the Authenticator application for letting the user create account or request access.
   */
  async startRegistrationProcess(
    registrationType: 'create-account' | 'request-access',
    countryId = '',
    channel = '',
    clientId?: string
  ) {
    const openIdConfig = await this.fetchOpenIdConfig()
    const authorizationUrl = openIdConfig.data?.authorization_endpoint || ''
    const registrationRequest = await this.authRequestProvider.createRegistrationRequest(
      authorizationUrl,
      registrationType,
      countryId,
      clientId
    )
    this.storage.setFlow(registrationRequest.flow)

    window.location.href = registrationRequest.url.includes('?')
      ? `${registrationRequest.url}&channel=${channel}`
      : `${registrationRequest.url}?channel=${channel}`
  }

  /**
   * Processes the URL parameters added when coming back from a successful login,
   * stores them locally for the following API requests.
   */
  async getLoginState(product?: string): Promise<LoginResponse> {
    const isSignIn = window.location.href.indexOf(`${window.origin}/auth`) === 0
    const isSignOut = window.location.href.indexOf(`${window.origin}/auth/callback/logout`) === 0

    if (!isSignIn && !isSignOut && !product) {
      const {accessToken} = this.storage.getToken()
      if (!accessToken) {
        return {loggedIn: false}
      }
      const decodedToken = jwtDecode<DecodedToken>(accessToken)
      return {loggedIn: true, decodedToken}
    }

    if (isSignOut) {
      // Remove our own fields which are just use temporary during
      // exchange with the authentication system.
      this.storage.resetFlow()
      window.history.replaceState(null, '', window.origin)
      return {loggedIn: false}
    }

    try {
      const flowState = this.storage.getFlow()
      const tokenResponse = await this.exchangeAuthorizationCode(flowState, product)

      if (tokenResponse.access_token && tokenResponse.refresh_token) {
        const token = convertAuthToApiToken({
          id_token: tokenResponse.id_token,
          access_token: tokenResponse.access_token,
          refresh_token: tokenResponse.refresh_token
        })
        // Store the rest using the native method
        this.storage.setToken(token)

        // Remove our own fields which are just use temporary during
        // exchange with the authentication system.
        this.storage.resetFlow()

        const decodedToken = jwtDecode<DecodedToken>(tokenResponse.access_token)

        if (window.origin.includes(this.backendSelector.getSelectedBackend().AUTH_URL)) {
          return {loggedIn: true, decodedToken, token}
        }

        if (flowState.href) {
          const [, path] = flowState.href.split(window.origin)
          window.location.replace(path)
          return {loggedIn: true, decodedToken}
        }

        return {loggedIn: true, decodedToken}
      } else {
        console.warn('Received no access or refresh token')
        window.history.replaceState(null, '', window.origin)
        return {loggedIn: false}
      }
    } catch (error) {
      console.error(error)
      window.history.replaceState(null, '', window.origin)
      return {loggedIn: false}
    }
  }
  private async exchangeAuthorizationCode(
    flowState: LoginFlowState,
    product?: string
  ): Promise<TokenResponse> {
    const queryParams = qs.parse(window.location.search) as AuthRedirectQueryParams
    if (queryParams.error) {
      throw new Error(`There has been an error while signin: ${queryParams.error_description}`)
    }
    if (!queryParams.code) {
      throw new Error('There was no code returned from IdentityServer, aborting login flow.')
    }
    if (flowState.state !== queryParams.state) {
      throw new Error('There has been a state mismatch.')
    }

    const params = new URLSearchParams()
    params.append('grant_type', 'authorization_code')
    params.append('client_id', product ? product : this.authRequestProvider.getClientId())
    params.append('code', queryParams.code)
    params.append('code_verifier', flowState.codeVerifier)
    params.append(
      'redirect_uri',
      product
        ? this.backendSelector.getSelectedBackend().AUTH_URL
        : this.authRequestProvider.getAuthSuccessRedirectUrl()
    )

    const openIdConfig = await this.fetchOpenIdConfig()
    const tokenUrl = openIdConfig.data?.token_endpoint || ''

    const tokenResponse = (await axios({
      method: 'post',
      url: tokenUrl,
      data: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })) as AxiosResponse<TokenResponse>

    return tokenResponse.data
  }

  async fetchOpenIdConfig(): Promise<AxiosResponse<OpenIdConfig | undefined>> {
    const authenticatorBaseUrl = this.backendSelector.getSelectedBackend().AUTH_URL
    return await axios.get(`${authenticatorBaseUrl}/.well-known/openid-configuration`)
  }
}
