import {Branding} from '../Branding.types'

import LogoHM from './icons/Logo-HM-Compact-Negative.svg'

export const hubHeidelbergMaterialsComAu: Branding = {
  nationalBrandName: 'Heidelberg Materials',
  nationalLogoUrl: LogoHM,
  nationalLogoTitle: 'Heidelberg Materials',
  nationalUrl: 'hub.heidelbergmaterials.com.au',
  contactEmail: 'aus-support@hconnect.digital',
  contactEmailSubject: 'Mail from Hub Portal',

  pageTitle: 'Heidelberg Materials',

  country: 'AU',
  termsAndConditionsOfSale:
    'https://www.heidelbergmaterials.com.au/en/terms-and-conditions-of-sale',
  paymentConfirmationMail: 'aus.nationalcreditsupport@aus.heidelbergmaterials.com',
  paymentGuideLink: 'https://www.hanson.com.au/media/8252/hg163hn_hub_payment_function_v4.pdf',
  digitalDocketsGuideLink: 'https://www.hanson.com.au/hanson_external_docket_format_update.pdf'
}
