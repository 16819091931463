import predictContactPersonImageSrc from '../assets/ContactPersonImage/predict.jpeg'
import {PLANT_ID} from '../consts'
import {AppConfig} from '../types'

export const predict: AppConfig = {
  contact: {
    name: 'Dr. Christoph Deil',
    mail: 'christoph.deil@heidelbergmaterials.com',
    profileImage: predictContactPersonImageSrc
  },
  name: 'Predict',
  url: {
    qa: `https://predict-qa.hproduce.digital/plants/${PLANT_ID}`,
    uat: `https://predict-uat.hproduce.digital/plants/${PLANT_ID}`,
    prod: `https://predict.hproduce.digital/plants/${PLANT_ID}`
  },
  whiteListedPermissionTypes: ['VIEW_PREDICTIONS'], // ENERGY_MANAGER is missing the VIEW_PREDICTIONS permission
  plant: {
    // Note: predict is temporarily disabled for all plants
    whiteList: []
  }
}
